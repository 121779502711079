import { Theme } from 'theme-ui';

const fontd = `mallory-microplus`; // "Mallory"

const functheme: Theme = {
  useColorSchemeMediaQuery: true,
  colors: {
    text: `#212529`,
    background: `#fff`,
    action: `#07074E`,
    tag: `rgb(0 0 0 / 3.75%)`,
    divider: `rgb(0 0 0 / 12.5%)`,
    selection: `#ddd`,
    primary: `#000`,
    secondary: `#1899DA`, // ``,'#84FDF1',
    primarytext: `#212529`,
    primarytextinvert: `white`,
    white50: [`#d8d5f58c`],
    white25: [`#b1def6b3`],
    white10: [`#b1def638`],
    white: `#000`,
    Wwhite23: `#fff`,
    bg: `#000`,
    base: `#fff`,
    // grays.1
    basealt: `grays.0`,
    border: `red.6`,
    Wcard: `#EEEEEE`,
    Stext: `#333333`,
    bgNav: `#f8f9fa`,
    hover: `#5E5E5E`,
    Dcolor: `#5E5E5E`,
    Acolor: `#464646`,
    dividerDim: `#e9ecef`, // grays.1 2a1f5d
    dimText: `#6B7785`,
    dimText2: `#868e96`,
    dark: [
      `#08084a`,
      `#0d0740`,
      `#100636`,
      `#12052c`,
      `#110323`,
      `#0d021a`,
      `#070110`,
      `#000000`,
    ],
    light: [
      `#08084a`,
      `#2a1f5d`,
      `#453770`,
      `#5f5183`,
      `#786b97`,
      `#9287ab`,
      `#ada4c0`,
      `#c8c1d5`,
      `#e3e0ea`,
      `#ffffff`,
    ],
    grays: [
      `#f8f9fa`,
      `#f1f3f5`, // 1
      `#e9ecef`,
      `#dee2e6`,
      `#ced4da`,
      `#adb5bd`,
      `#868e96`,
      `#495057`,
      `#343a40`,
      `#212529`,
    ],
    dim: [
      `#080B4D`,
      `#1A1D5E`,
      `#2C2F6F`,
      `#3F4280`,
      `#54558F`,
      `#69699F`,
      `#7E7EAE`,
      `#9393BC`,
      `#A8A8CA`,
      `#BDBDD8`,
      `#D2D2E5`,
      `#E7E7F2`,
      `#FCFCFE`,
    ],

    red: [
      `#fff5f5`,
      `#ffe3e3`,
      `#ffc9c9`,
      `#ffa8a8`,
      `#ff8787`,
      `#ff6b6b`,
      `#fa5252`,
      `#f03e3e`,
      `#e03131`,
      `#c92a2a`,
    ],
    pink: [
      `#fff0f6`,
      `#ffdeeb`,
      `#fcc2d7`,
      `#faa2c1`,
      `#f783ac`,
      `#f06595`,
      `#e64980`,
      `#d6336c`,
      `#c2255c`,
      `#a61e4d`,
    ],
    grape: [
      `#f8f0fc`,
      `#f3d9fa`,
      `#eebefa`,
      `#e599f7`,
      `#da77f2`,
      `#cc5de8`,
      `#be4bdb`,
      `#ae3ec9`,
      `#9c36b5`,
      `#862e9c`,
    ],
    violet: [
      `#f3f0ff`,
      `#e5dbff`,
      `#d0bfff`,
      `#b197fc`,
      `#9775fa`,
      `#845ef7`,
      `#7950f2`,
      `#7048e8`,
      `#6741d9`,
      `#5f3dc4`,
    ],
    indigo: [
      `#edf2ff`,
      `#dbe4ff`,
      `#bac8ff`,
      `#91a7ff`,
      `#748ffc`,
      `#5c7cfa`,
      `#4c6ef5`,
      `#4263eb`,
      `#3b5bdb`,
      `#364fc7`,
    ],
    blue: [
      `#e7f5ff`,
      `#d0ebff`,
      `#a5d8ff`,
      `#74c0fc`,
      `#4dabf7`,
      `#339af0`,
      `#228be6`,
      `#1c7ed6`,
      `#1971c2`,
      `#1864ab`,
    ],
    cyan: [
      `#e3fafc`,
      `#c5f6fa`,
      `#99e9f2`,
      `#66d9e8`,
      `#3bc9db`,
      `#22b8cf`,
      `#15aabf`,
      `#1098ad`,
      `#0c8599`,
      `#0b7285`,
    ],
    teal: [
      `#e6fcf5`,
      `#c3fae8`,
      `#96f2d7`,
      `#63e6be`,
      `#38d9a9`,
      `#20c997`,
      `#12b886`,
      `#0ca678`,
      `#099268`,
      `#087f5b`,
    ],
    green: [
      `#ebfbee`,
      `#d3f9d8`,
      `#b2f2bb`,
      `#8ce99a`,
      `#69db7c`,
      `#51cf66`,
      `#40c057`,
      `#37b24d`,
      `#2f9e44`,
      `#2b8a3e`,
    ],
    lime: [
      `#f4fce3`,
      `#e9fac8`,
      `#d8f5a2`,
      `#c0eb75`,
      `#a9e34b`,
      `#94d82d`,
      `#82c91e`,
      `#74b816`,
      `#66a80f`,
      `#5c940d`,
    ],
    yellow: [
      `#fff9db`,
      `#fff3bf`,
      `#ffec99`,
      `#ffe066`,
      `#ffd43b`,
      `#fcc419`,
      `#fab005`,
      `#f59f00`,
      `#f08c00`,
      `#e67700`,
    ],
    orange: [
      `#fff4e6`,
      `#ffe8cc`,
      `#ffd8a8`,
      `#ffc078`,
      `#ffa94d`,
      `#ff922b`,
      `#fd7e14`,
      `#f76707`,
      `#e8590c`,
      `#d9480f`,
    ],
    modes: {
      dark: {
        bgNav: `#000`,
        text: `#f8f9fa`,
        background: `#000`,
        primary: `#212529`,
        // white: `#000`,
        base: `#000`,
        white: `#fff`,
        Wcard: `#4F4F4F`,
        Stext: `#ECE6DAB2`,
        hover: `#ABABAB`,
        Dcolor: `#303030`,
        Acolor: `#fff`,
        Wwhite23: `#000`,
        dividerDim: `#212529`, // grays.1
        dimText: `#868e96`,
        dimText2: `#868e96`,
        grays: [
          `#212529`,
          `#343a40`,
          `#495057`,
          `#868e96`,
          `#adb5bd`,
          `#ced4da`,
          `#dee2e6`,
          `#e9ecef`,
          `#f1f3f5`,
          `#f8f9fa`,
        ],
        // grays: [
        //   `#fff`,
        //   `#111`,
        //   `#222`,
        //   `#333`,
        //   `#444`,
        //   `#555`,
        //   `#666`,
        //   `green`,
        //   `blue`,
        //   `red`,
        // ],
      },
    },
  },
  fontSizes: [12.8, 16, 20, 22.5, 25, 31.25, 39.06, 48.83, 61.04],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  layout: {
    container: {
      maxWidth: [`100 % `, `70ch`, `85vw`],
      px: [4, 4, 0],
    },
  },
  // breakpoints: ['14ch', '40ch', '80ch'],
  fonts: {
    // body: `${ fontd }, Avenir, system - ui, -apple - system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans - serif`,
    body: `mallory-microplus`,
    heading: `inherit`,
    heading2: `${fontd}, Avenir, system - ui, -apple - system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    heading3: `${fontd}, Avenir, system - ui, -apple - system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    body2: `${fontd}, Avenir, system - ui, -apple - system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    body3: `${fontd}, Avenir, system - ui, -apple - system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    monospace: `Menlo, monospace`,
  },
  fontWeights: {
    thin: 200,
    body: 400,
    heading: 500,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.1275,
  },
  text: {
    heroTitle: {
      fontSize: [5, 4, 7],
      fontFamily: `heading`,
      lineHeight: `heading`,
      textAlign: [`left`, `left`, `left`],
      fontWeight: [`bold`, `bold`, `bold`],
      letterSpacing: `-0.05rem`,
      mb: 3,
      color: `grays.9`,
    },
    talkTitle: {
      marginTop: `120px`,
      fontWeight: `500`,
      fontSize: `29.3px`,
      lineHeight: `41px`,
    },
    LtalkButton: {
      width: `292px`,
      height: `56px`,
      backgroundColor: `Wwhite23`,
      marginTop: `24px`,
      display: `flex`,
      alignItems: `center`,
      cursor: `pointer`,
      justifyContent: `center`,
      color: `text`,
      border: `1px solid #4E4E4E`,
    },
    talkButton: {
      px: 5,
      py: 3,
      // width: `292px`,
      // height: `56px`,
      bg: `grays.0`,
      marginTop: `24px`,
      display: `flex`,
      alignItems: `center`,
      cursor: `pointer`,
      justifyContent: `center`,
      color: `grays.6`,
      fontWeight: `600`,
    },
    talkButtonAlter: {
      width: `292px`,
      height: `56px`,
      backgroundColor: `Wcard`,
      marginTop: `24px`,
      display: `flex`,
      alignItems: `center`,
      cursor: `pointer`,
      justifyContent: `center`,
      color: `bg`,
    },
    titleDesc: {
      fontWeight: `400`,
      fontSize: 1,
      lineHeight: 1.5,
      color: `grays.7`,
    },
    blockTitle: {
      py: 1,
      width: `auto`,
      listStyle: `none`,
      // display: `block`,
      borderBottom: `solid 1px`,
      borderBottomColor: `blue.4`,
      pl: 0,
      ml: 0,
      mb: 0,
      // width: `40%`,
      // float: `left`,
      // mr: 4,
      fontSize: 1,
      fontWeight: `bold`,
      lineHeight: `heading`,
      flex: 1,
      // '&:last-child': {
      //   // borderBottom: 1,
      //   // borderBottomColor: `transparent`,
      // },
    },
    // talkTitle: {
    //   marginTop: `120px`,
    //   fontWeight: `500`,
    //   fontSize: `29.3px`,
    //   lineHeight: `41px`,
    // },
    // talkButton: {
    //   width: `292px`,
    //   height: `56px`,
    //   backgroundColor: `white`,
    //   marginTop: `24px`,
    //   display: `flex`,
    //   alignItems: `center`,
    //   cursor: `pointer`,
    //   justifyContent: `center`,
    //   color: `Wcard`,
    // },
    // titleDesc: {
    //   fontWeight: `400`,
    //   fontSize: `23.3px`,
    //   lineHeight: `33px`,
    // },
    heroDesc: {
      fontSize: [2, 4, 4],
      textAlign: [`left`, `left`, `left`],
      mb: 4,
      lineHeight: `heading`,
      opacity: 1,
      color: `grays.1`,
      fontWeight: `300`,
      maxWidth: [`auto`, `auto`, `23ch`],
      // '::selection': {
      //   backgroundColor: `background`,
      // },
    },
    personDesc: {
      fontSize: [1, 1, 1],
      color: `dimText2`,
      fontWeight: `400`,
      // '::selection': {
      //   backgroundColor: `background`,
      // },
    },
    personName: {
      fontSize: [1, 1, 1],
      mb: 0,
      fontWeight: `400`,
      // '::selection': {
      //   backgroundColor: `background`,
      // },
    },
    blockName: {
      fontSize: [2, 1, 2],
      mb: 1,
      fontWeight: `bold`,
      // '::selection': {
      //   backgroundColor: `background`,
      // },
    },
    personsName: {
      fontSize: [0, 1, 2],
      color: `grays.4`,
      fontWeight: 100,
    },
    pageTitle: {
      fontSize: [4, 4, 4],
      mb: 2,
      lineHeight: `heading`,
      color: `text`,
    },
    pageLabel: {
      mb: [0, 4, 4],
      fontSize: 1,
      fontWeight: 400,
      color: `dimText`,
    },
    pageDesc: {
      // fontSize: [3, 3, 3],
      // fontSize: [2, 2, 2],
      fontSize: [3, 4, 4],
      lineHeight: `body`,
      color: `grays.8`,
      fontWeight: `heading`,
      mb: 3,
    },
    linkTo: {
      color: `grays.9`,
      fontWeight: 400,
      fontSize: 2,
    },
    shortTitle: {
      fontSize: 3,
      my: 2,
      // textTransform: 'uppercase',
      color: `grays.9`,
    },
    cardDesc: {
      fontSize: 0,
      fontWeight: 100,
      color: `dimText2`,
    },
    dev: {
      // color: ['red.6', 'blue.6', 'green.6'],
    },
    AreaTitle: {
      color: `green.1`,
      textTransform: `uppercase`,
      fontSize: 1,
      textAlign: [`center`, `left`, `left`],
    },
    title1: {
      color: `grays.3`,
      fontWeight: `body`,
      textAlign: [`center`, `left`, `left`],
    },
    blockInfo: {
      variant: `text.title1`,
      fontSize: 2,
      color: `grays.7`,
      fontWeight: `body`,
    },
    blockDesc: {
      variant: `text.title1`,
      fontSize: 0,
    },
    blockTitle: {
      // variant: 'text.dev',
      color: `grays.7`,
      fontSize: [3, 3, 3],
      textAlign: [`left`, `left`, `left`],
      fontWeight: `bold`,
    },
    blockTitleSmall: {
      // variant: 'text.dev',
      color: `grays.7`,
      fontSize: [1, 2, 0],
      textAlign: [`center`, `left`, `left`],
      fontWeight: `thin`,
    },
    blockTitleCentre: {
      variant: `text.blockTitle`,
      textAlign: [`center`, `left`, `left`],
      fontWeight: `body`,
      fontSize: [1, 2, 4],
    },
    blockTitleCentreB: {
      variant: `text.blockTitle`,
      textAlign: [`center`, `left`, `left`],
      fontWeight: `bold`,
      fontSize: [1, 2, 4],
      // color: 'red',
    },
    blockInfoCenter: {
      variant: `text.blockTitle`,
      textAlign: [`center`, `left`, `left`],
      fontWeight: 100,
      fontSize: [1, 2, 4],
      color: `grays.6`,
      // variant: 'text.dev',
    },
    section: {
      bg: `red.3`,
    },
    blockTitleRight: {
      variant: `text.blockTitle`,
      textAlign: `right`,
    },
    blockTitleLeft: {
      variant: `text.blockTitle`,
      textAlign: `left`,
    },
    smallSection: {
      fontWeight: 600,
      color: `primarytext`,
      mb: 1,
      mt: 3,
    },
    // personName: {
    //   pb: 2,
    //   fontSize: [1, 2, 3],
    //   mt: 2,
    //   // fv
    // },
    personBio: {
      pb: 1,
      fontSize: 2,
      m: 0,
    },
    sectionTitle: {
      fontSize: [4, 4, 4],
      mb: [2, 2, 2],
      textAlign: [`left`, `center`, `left`], // 'left',
      pt: 5,
      pl: 0,
      fontWeight: `700`,
      // color: 'teal.5',
      fontFamily: `heading`,
    },
    sectionTitleX: {
      variant: `text.sectionTitle`,
      fontSize: 3,
      fontWeight: `bold`,
      lineHeight: `heading`,
    },
    sectionTitleSmall: {
      variant: `text.sectionTitle`,
      fontSize: 1,
      textTransform: `uppercase`,
      fontWeight: `bold`,
      letterspacing: `1px`,
      color: `grays.6`,
      lineHeight: `heading`,
    },
    sectionContent: {
      maxWidth: [`inherit`, `inherit`, `60ch`],
      fontSize: [3, 3, 4],
      color: `grays.9`,
      my: [2, 2, 2],
      lineHeight: `body`,
      fontWeight: 300,
    },
    sectionBody: {
      maxWidth: [`inherit`, `inherit`, `60ch`],
      fontSize: 4,
      color: `grays.9`,
      lineHeight: `body`,
      fontWeight: 300,
    },
    sectionBodyDim: {
      maxWidth: [`inherit`, `inherit`, `60ch`],
      opacity: 0.7,
      pt: 1,
    },
    sectionTitleCenter: {
      variant: `text.sectionTitle`,
      textAlign: [`center`, `center`, `center`],
    },
    body: {
      fontSize: 4,
      width: [`inherit`, `inherit`, `55ch`],
      color: `text`,
      fontWeight: `body`,
      pb: 1,
    },
    para: {
      fontSize: [2, 3, 3],
      pt: 0,
      fontFamily: `heading2`,
      // color: 'red'
    },
    paraBold: {
      variant: `text.para`,
      fontWeight: `bold`,
      mt: 0,
    },
    bodyDim: {
      color: `bodytext`,
      opacity: `0.755`,
      fontWeight: 100,
      textAlign: [`left`, `left`, `left`],
    },
    bodyDimC: {
      variant: `text.bodyDim`,
      textAlign: [`center`, `center`, `center`],
      fontSize: [3, 3, 3],
      pl: [3, 0, 0],
      lineHeight: [`1.35`],
    },
    heading: {
      color: `blue.0`,
      fontSize: [4, 4, 4],
      fontWeight: `body`,
      textAlign: [`left`, `left`, `left`],
      pb: 2,
    },
    heading2: {
      color: `primarytext`,
      fontSize: [3, 3, 3],
      fontWeight: 600,
      textAlign: [`center`, `center`, `center`],
      pb: 2,
    },
    // heading3: {
    //   color: 'primarytext',
    //   fontSize: [3, 3, 3],
    //   fontWeight: 600,
    //   textAlign: ['left', 'center', 'center'],
    //   pb: 2,
    // },
    headingInv: {
      color: `primarytextinvert`,
      fontSize: [4, 4, 4],
      fontWeight: `body`,
      textAlign: [`left`, `left`, `left`],
      pb: 2,
    },
    heading3: {
      color: `primarytext`,
      fontSize: [3, 3, 3],
      fontWeight: `body`,
      textAlign: [`left`, `left`, `left`],
      pb: 2,
    },
    smallHeading: {
      fontWeight: `bold`,
      // color:'red',
    },
  },
  buttons: {
    btnLink: {
      fontWeight: `bold`,
      fontSize: 3,
    },
    btnWidePrimary: {
      // width: `100 % `,
      fontSize: 0,
      textTransform: `uppercase`,
      borderRadius: 0,
      border: `solid 1px`,
      borderColor: `grays.2`,
      letterSpacing: 0.5,
      // fontWeight: 400,
      mt: 3,
      mb: 2,
      fontWeight: `heading`,
      fontFamily: `heading`,
      py: 3,
      px: 5,
      bg: `transparent`,
      color: `grays.9`,
      '&:hover': {
        bg: `blue.8`,
      },
    },
    btnWideSecond: {
      variant: `buttons.btnWidePrimary`,
      bg: `green.9`,
      color: `green.0`,
      '&:hover': {
        bg: `green.8`,
      },
    },
    outline: {
      border: `solid 1px`,
      borderRadius: 3,
      fontWeight: 600,
      fontSize: 2,
    },
    primary: {
      bg: `teal.4`,
      fontFamily: `body`,
      fontSize: 1,
      display: `block`,
      fontWeight: `bold`,
      // color: 'white',
      borderRadius: 99,
      // display: 'inline-block',
      // fontSize: 1,
      // px: 3,
      // bg: 'primary',
      '&:hover': {
        bg: `teal.6`,
      },
    },
    secondary: {
      color: `teal.1`,
      bg: `teal.6`,
    },
  },
  forms: {
    input: {
      default: {
        borderRadius: 0,
      },
    },
  },
  links: {
    menu: {
      FontFamily: `body`,
    },
    blockTitle: {
      variant: `text.dev`,
      color: `grays.7`,
      fontSize: [1, 2, 3],
      textAlign: [`center`, `left`, `left`],
      fontWeight: `thin`,
    },
    blockTitleLink: {
      base: `blockTitle`,
      color: `grays.8`,
      fontSize: 4,
      mt: 2,
      textDecoration: `none`,
      lineHeight: `heading`,
      fontWeight: `bold`,
    },
    navListBlock: {
      px: [2, 3],
      pt: 3,
      height: [`auto`, `auto`, 48],
      fontSize: 2,
      alignItems: `center`,
      fontWeight: `bold`,
      fontFamily: `heading`,
      pb: 2,
      color: `red.9`,
      bg: `red`,
      width: `100 % `,
      ':hover': {
        color: `secondary`,
        textDecoration: `none`,
      },
    },
    button: {
      mt: 3,
      p: 2,
      fontSize: 1,
      borderRadius: 2,
      pl: 3,
      pr: 3,
      bg: `primary`,
      ':hover': {
        bg: `grays.9`,
      },
      color: `primarytextinvert`,
    },
    bold: {
      fontWeight: `bold`,
    },
    nav: {
      fontWeight: `bold`,
      color: `inherit`,
      textDecoration: `none`,
    },
  },
  styles: {
    pageFrame: {
      py: [7, 7, 0],
      px: [4, 3, 3],
    },
    btnLink: {
      fontWeight: `bold`,
      fontSize: 4,
    },
    plateHero: {
      borderBottom: `2px solid gray`,
    },
    footer: {
      borderTop: `2px solid gray`,
    },
    contentFrame: {
      // px: 4,
      // bg: 'red',
    },
    bodyFrame: {
      maxWidth: `80ch`,
      mx: `auto`,
      // bg: 'blue.1',
    },
    sectionBlock: {
      maxWidth: `100ch`,
      // bg: 'blue',
    },
    bodyFrame2: {
      // maxWidth: `100 % `,
      // bg: 'blue',fv
      m: 0,
    },
    root: {
      fontFamily: `body`,
    },
    navListx: {
      fontFamily: `mallory-microplus`,
      fontWeight: `500`,
      fontSize: `21.44px`,
      lineHeight: `33px`,
      height: `33px`,
    },
    // nav
    navLink: {
      listStyle: `none`,
      alignItems: `center`,
      fontWeight: `500`,
      ml: 4,
      fontSize: `21px`,
    },
    navGroup: {
      ml: `auto`,
      justifyContent: `center`,
      alignItems: `center`,
      // bg: 'green',
      display: [`flex`, `inherit`, `inherit`],
    },
    navList: {
      my: 0,
      pl: [3, 3, 0],
      flex: 1,
      minHeight: [`100vh`, `auto`, `auto`],
      mr: 0,
      justifyContent: [`flex - start`, `flex - start`, `flex - end`],
      flexDirection: [`column`, `column`, `row`],
      listStyleType: `none`,
      flexGrow: 1,
      width: `100 % `,
      fontSize: 3,
      left: 0,
      right: 0,
      pt: [3, 3, 0],
      pr: 5,
    },
    heroBlock: {
      bg: `base`,
      minHeight: `90vh`,
      height: `100 % `,
    },
    navListBlock: {
      px: [2, 3],
      pt: 3,
      height: [`auto`, `auto`, 48],
      fontSize: 2,
      alignItems: `center`,
      fontWeight: `bold`,
      fontFamily: `heading`,
      pb: 2,
      color: `red.9`,
      bg: `red`,
      width: `100 % `,
      ':hover': {
        color: `secondary`,
        textDecoration: `none`,
      },
    },
    cardBlock: {
      py: [0, 0, 3],
      verticalAlign: `top`,
      px: [0, 2, 3],
      // borderBottom: `solid 1px`,
      borderColor: `grays.1`,
      '&:last-child': { border: 0 },
    },
    textBlockTight: {
      variant: `box.textBlock`,
      px: 0,
    },
    boxSectionHeading: {
      // bg: 'red.4',
      // p: 4,
      px: 3,
      py: 1,
      // borderBottom: 'solid 0.5px',
      borderColor: `grays.8`,
      // bg: 'red',
    },
    cardFrame: {
      bg: `grays.9`,
      borderTop: `solid 1px`,
      borderColor: `grays.8`,
      right: 0,
      bottom: 0,
      left: 0,
      px: 3,
      py: 2,
      pb: 4,
      fontSize: 1,
      fontWeight: 900,
      position: `absolute`,
      color: `grays.1`,
    },
    textBlock: {
      mb: 4,
      pb: 4,
      borderBottom: `solid 1px`,
      borderColor: `grays.2`,
      alignItems: [`center`, `left`, `left`],
    },
    boxSection: {
      pb: 2,
      mb: 2,
    },
    gridly: {
      flexDirection: [`column`, `row`, `row`],
      alignItems: `center`,
      verticalAlign: `middle`,
      textAlign: `center`,
    },
    gridly2: {
      flexDirection: [`row`, `row`, `row`],
      alignItems: [`center`, `left`, `left`],
      verticalAlign: `middle`,
      textAlign: [`center`, `left`, `left`],
    },
    gridly3: {
      // pt: 4,
      flexDirection: [`column`, `column`, `row`],
      alignItems: `center`,
      justifyContent: `space - between`,
    },
    dotted: {
      pr: [3, 0, 0],
      maxWidth: `42ch`,
      mb: 1,
      borderBottom: `solid 1px`,
      borderColor: `grays.7`,
      paddingBottom: `1rem`,
    },
    textbox: {
      textAlign: [`left`, `left`, `center`],
    },
    frame1: {
      bg: `base`,
    },
    frame2: {
      base: `base`,
      pb: 7,
      pt: 6,
      borderBottom: `solid 1px`,
      borderColor: `grays.3`,
    },
    plateBase: {
      // maxWidth: '110ch',
      flexWrap: `wrap`,
      alignItems: `center`,
      // px: [3,5,5],
      mx: `auto`,
      bg: `red.8`,
    },
    plate6: {
      base: `plateBase`,
      // bg: 'red.8',
    },
    plateTint: {
      bg: `grays.0`,
      // pb: 5,
    },
    plateTint2: {
      bg: `grays.1`,
      py: 7,
    },
    plateTint3: {
      bg: `base`,
      py: 6,
    },
    iconbox: {
      mb: 1,
      mt: 0,
      mx: `auto`,
    },
    primarytextinverted: {},
    light: {
      color: `primarytextinvert`,
    },
    filled: {
      bg: `dark.0`,
      pb: 5,
    },
    hidden: {
      display: `none`,
    },
    inverted: {
      color: `primarytextinvert`,
    },
    plateMedium: {
      py: [2, 2, 4],
      // bg: 'red.1',
      // color: 'primarytext',
      backgroundSize: `cover`,
    },
    plateMediumTall: {
      variant: `box.plateMedium`,
      py: [3, 3, 5],
      borderBottom: `solid 1px`,
      borderBottomColor: `grays.2`,
      textAlign: [`left`, `left`, `left`],
    },
    tallX: {
      pb: 5,
    },
    plateMediumTallTight: {
      variant: `box.plateMediumTall`,
      p: 0,
    },
    plateMediumTallClean2: {
      p: 0,
      pl: 0,
      // height: '48px',
      bg: `grays.0`,
    },
    plateBig: {
      py: 6,
      pl: [4, 4, 4],
      pt: 6,
      // bg: 'red',
      // color: 'grays.1',
    },
  },
  images: {
    featureIcon: {
      width: [`100 % `, `60px`, `80px`],
      height: `auto`,
      ml: [0, 0, `auto`],
      mt: 2,
      // mr: 7,
      // bg:'primary',
    },
  },
};

export default functheme;
